var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":""}},[(_vm.singleItemLoading)?_c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"purple"}})],1)],1):_vm._e(),_c('v-toolbar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.singleItemLoading),expression:"!singleItemLoading"}],attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("PAGES.RESERVATIONS.DIALOG.TITLE"))+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DIALOG.SAVE"))+" ")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.singleItemLoading),expression:"!singleItemLoading"}]},[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"disabled":true,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.DIALOG.CODE')},model:{value:(_vm.singleItem.code),callback:function ($$v) {_vm.$set(_vm.singleItem, "code", $$v)},expression:"singleItem.code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.owner(),"disabled":true,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.ATTRIBUTES.OWNER')}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"value":_vm.singleItem.createdAt != null
                    ? new Date(_vm.singleItem.createdAt).toLocaleString()
                    : '---',"disabled":true,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.ATTRIBUTES.CREATED_AT')}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"value":_vm.singleItem.updatedAt != null
                    ? new Date(_vm.singleItem.updatedAt).toLocaleString()
                    : '---',"disabled":true,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.ATTRIBUTES.UPDATED_AT')}})],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.isDisabled,"items":_vm.eventsActiveList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.ATTRIBUTES.EVENT'),"item-text":"title","item-value":"id","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var tile = ref.tile;
return [_vm._v(" "+_vm._s(item.title)+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.title)+" ")]}}]),model:{value:(_vm.singleItem.event),callback:function ($$v) {_vm.$set(_vm.singleItem, "event", $$v)},expression:"singleItem.event"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.isDisabled,"items":_vm.dosesActiveList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.ATTRIBUTES.EVENT'),"item-text":"title","item-value":"id","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var tile = ref.tile;
return [_vm._v(" "+_vm._s(item.title)+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.title)+" ")]}}]),model:{value:(_vm.singleItem.dose.id),callback:function ($$v) {_vm.$set(_vm.singleItem.dose, "id", $$v)},expression:"singleItem.dose.id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.isDisabled,"items":_vm.datesActiveList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.RESERVATIONS.ATTRIBUTES.DATE_TIME_RESERVATION'),"item-text":"date","item-value":"date","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var tile = ref.tile;
return [_vm._v(" "+_vm._s(new Date(item.date).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", }))+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(new Date(data.item.date).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", }))+" ")]}}]),model:{value:(_vm.singleItem.reservation_date_time),callback:function ($$v) {_vm.$set(_vm.singleItem, "reservation_date_time", $$v)},expression:"singleItem.reservation_date_time"}})],1)],1)],1)],1)],1),_c('div',{staticStyle:{"flex":"1 1 auto"}})],1),_c('DialogConfirmation',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
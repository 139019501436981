<template>

  <div class="d-flex flex-column-fluid ">
    <div class="container">
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-4"
             id="resume_user_reservation_overview"
        >
          <div class="card card-custom card-stretch">
            <div class="card-body pt-4">
              <div class="d-flex justify-content-end"></div>
              <div class="d-flex align-items-center">
                <div>
                  <a
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESUME_USER") }}</a
                  >
                </div>
              </div>
              <v-divider></v-divider>
              <div class="py-2">
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.NAME") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.name
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.SURNAME") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      convertDate(reservationInUse.citizen.surname)
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.FISCAL_CODE") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.fiscal_code
                    }}</a>
                </div>
              </div>
              <v-divider></v-divider>
              <!--begin::Contact-->
              <div class="py-2">
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.DATE_OF_BIRTH") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      convertDate(reservationInUse.citizen.date_of_birth)
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.CITY_OF_BIRTH") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.city_of_birth.denomination
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_ADDRESS") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.residence_address
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_CITY") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.city_of_residence.denomination
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.EMAIL") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.email
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.PHONE") }}:</span
              >
                  <span class="text-muted">{{ reservationInUse.citizen.phone }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.MOBILE_PHONE") }}:</span
              >
                  <span class="text-muted">{{ reservationInUse.citizen.mobile_phone }}</span>
                </div>

              </div>
              <!--end::Contact-->
            </div>
          </div>

        </div>
        <div class="col-xl-4"
             id="resume_event_reservation_overview"
        >
          <div class="card card-custom card-stretch">
            <div class="card-body pt-4">
              <div class="d-flex justify-content-end"></div>
              <div class="d-flex align-items-center">
                <div>
                  <a
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESUME_EVENT") }}</a
                  >

                </div>
              </div>
              <v-divider></v-divider>
              <div class="py-2">
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.TITLE") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.event.title
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.CODE") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.event.code
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.LOCALITY") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.event.city.denomination
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.ADDRESS") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.event.address
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.FISCAL_CODE") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.citizen.fiscal_code
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.BEGIN_DATE") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      new Date(reservationInUse.event.start_date).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      })
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.END_DATE") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      new Date(reservationInUse.event.end_date).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      })
                    }}</a>
                </div>
              </div>
              <!--end::Contact-->
            </div>
          </div>
        </div>

        <div class="col-xl-4"
             id="resume_reservation_overview"
        >
          <div class="card card-custom card-stretch">
            <div class="card-body pt-4">
              <div class="d-flex justify-content-end"></div>
              <div class="d-flex align-items-center">
                <div>
                  <a
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESERVATION") }}</a
                  >

                </div>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-center">
                  <qrcode-vue :value="reservationInUse.code" :size="qrSize()" level="H"/>

                </div>
              </div>
              <v-divider></v-divider>
              <div class="py-2">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bold mr-2">{{ $t("PAGES.RESERVATIONS.OVERVIEW.CODE") }}:</span>
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.code
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bold mr-2">{{ $t("PAGES.RESERVATIONS.OVERVIEW.CREATION_DATE") }}:</span>
                  <a class="text-muted text-hover-primary">{{
                      new Date(reservationInUse.createdAt).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      })
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.RESERVATIONS.OVERVIEW.DATETIME") }}:</span
              >
                  <a class="text-muted text-hover-primary">{{
                      new Date(reservationInUse.reservation_date_time).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      })
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bold mr-2">{{ $t("PAGES.RESERVATIONS.OVERVIEW.DOSE") }}:</span>
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.dose.title
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bold mr-2">{{ $t("PAGES.RESERVATIONS.OVERVIEW.DOSE_DESCRIPTION") }}:</span>
                  <a class="text-muted text-hover-primary">{{
                      reservationInUse.dose.description
                    }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <a @click="deleteItem"
                     class="m-1 btn btn-block btn-sm btn-light-danger font-weight-bolder text-uppercase py-4">{{
                      $t("PAGES.RESERVATIONS.OVERVIEW.ABORT")
                    }}</a>
                  <a @click="manageItem"
                     class="m-1 btn btn-block btn-sm btn-light-warning font-weight-bolder text-uppercase py-4">{{
                      $t("PAGES.RESERVATIONS.OVERVIEW.EDIT")
                    }}</a>
                </div>
              </div>
              <!--end::Contact-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogConfirmation ref="confirm"></DialogConfirmation>
    <v-dialog
        :persistent="true"
        v-model="dialog_edit"
        :max-width="single_item_loading ? '300px' : '100%'"

        hide-overlay
        transition="dialog-bottom-transition"
    >
      <EditReservation
          v-bind:single-item-loading="single_item_loading"
          :single-item="reservationInUse"
          v-on:close-dialog="closeManageItem"
          @dialogLoading="updateDialogLoading"
      ></EditReservation>
    </v-dialog>
  </div>

</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
// import rules from "@/core/helper/rules.helper.js";
// import general from "@/core/helper/general.helper.js";
// import config from "@/core/config/general.config.json";
//
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";

import {
  DELETE_RESERVATION,
  GET_RESERVATION_BY_ID
} from "@/core/services/store/reservations.module";
import QrcodeVue from 'qrcode.vue';
import DialogConfirmation from "@/view/commons/DialogConfirmation";
import EditReservation from "@/view/pages/reservations/Edit";
// import DateBox from "@/view/commons/forms/DateBox";
// import LocationBoxes from "@/view/commons/forms/LocationBoxes";
//
export default {
  name: "ReservationsNew",
  data() {
    return {
      // getDenomination: 'asd',
      citizenInfo: {},
      currentUserPersonalInfo: {},
      dialogDelete: false,
      dialogVars: {
        area: {},
        message: {
          title: String,
          text: String,
        },
        type: String,
        route: false
      },
      single_item_loading: false,
      dialog_edit: false,
    }
  },
  components: {
    QrcodeVue,
    DialogConfirmation,
    EditReservation
    // DialogMessage,
    // , DateBox,
    // LocationBoxes
  },
  computed: {
    ...mapGetters([
      "reservationInUse",
    ]),
    getDenomination() {
      return this.reservationInUse.citizen.name + ' ' + this.reservationInUse.citizen.surname
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.RESERVATIONS.AREA")},
      {
        title: this.$t("PAGES.RESERVATIONS.OVERVIEW.TITLE_OVERVIEW")
      },
    ]);
    this.$store.dispatch(GET_RESERVATION_BY_ID, this.$route.params.id);

  },
  watch: {
    dialog_edit(val) {
      val || this.close();
    },
  },
  methods: {
    manageItem() {
      this.editedItem = Object.assign({}, this.reservationInUse);
      this.item_to_edit = this.reservationInUse;
      this.single_item_loading = false;
      this.dialog_edit = true;
    },
    updateDialogLoading(status) {
      this.single_item_loading = status;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeManageItem() {
      this.dialog_edit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem() {
      if (
          await this.$refs.confirm.open(
              this.$t("PAGES.RESERVATIONS.OVERVIEW.TITLE_DELETE_DIALOG"),
              this.$t("PAGES.RESERVATIONS.OVERVIEW.TEXT_DELETE_DIALOG")
          )
      ) {
        this.$store.dispatch(DELETE_RESERVATION, this.$route.params.id);
        this.$router.push({name: 'reservations_list'});
        // this.deleteRecord();
      }

    },
    qrSize() {
      if (window.innerWidth < 1300) {
        return 180
      }
      if (window.innerWidth < 1400) {
        return 200
      }
      if (window.innerWidth < 1500) {
        return 200
      }
      return 200;
    },
    convertDate(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
    },
    convertDateTime(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      })
    }

  },
  created() {
  }
};
</script>

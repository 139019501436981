<template>
  <div>
    <v-card tile>
      <v-container fill-height v-if="singleItemLoading">
        <v-layout row justify-center align-center>
          <v-progress-circular
              indeterminate
              :size="100"
              :width="7"
              color="purple"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <v-toolbar v-show="!singleItemLoading" flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
            $t("PAGES.RESERVATIONS.DIALOG.TITLE")
          }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save()">
            {{ $t("COMMON.DIALOG.SAVE") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-show="!singleItemLoading">
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                    v-model="singleItem.code"
                    :disabled="true"
                    filled
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.RESERVATIONS.DIALOG.CODE')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    :value="owner()"
                    :disabled="true"
                    filled
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.RESERVATIONS.ATTRIBUTES.OWNER')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                    :value="
                    singleItem.createdAt != null
                      ? new Date(singleItem.createdAt).toLocaleString()
                      : '---'
                  "
                    :disabled="true"
                    filled
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.RESERVATIONS.ATTRIBUTES.CREATED_AT')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                    :value="
                    singleItem.updatedAt != null
                      ? new Date(singleItem.updatedAt).toLocaleString()
                      : '---'
                  "
                    :disabled="true"
                    filled
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.RESERVATIONS.ATTRIBUTES.UPDATED_AT')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete
                    v-model="singleItem.event"
                    :disabled="isDisabled"
                    :items="eventsActiveList"
                    filled
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.RESERVATIONS.ATTRIBUTES.EVENT')"
                    item-text="title"
                    item-value="id"
                    clearable
                >
                  <template slot="selection" slot-scope="{ item, tile }">
                    {{ item.title }} {{ tile }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.title }}
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                    v-model="singleItem.dose.id"
                    :disabled="isDisabled"
                    :items="dosesActiveList"
                    filled
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.RESERVATIONS.ATTRIBUTES.EVENT')"
                    item-text="title"
                    item-value="id"
                    clearable
                >
                  <template slot="selection" slot-scope="{ item, tile }">
                    {{ item.title }} {{ tile }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.title }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                    v-model="singleItem.reservation_date_time"
                    :disabled="isDisabled"
                    :items="datesActiveList"
                    filled
                    color="blue-grey lighten-2"
                    :label="
                    $t('PAGES.RESERVATIONS.ATTRIBUTES.DATE_TIME_RESERVATION')
                  "
                    item-text="date"
                    item-value="date"
                    clearable
                >
                  <template slot="selection" slot-scope="{ item, tile }">
                    {{
                      new Date(item.date).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}
                    {{ tile }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{
                      new Date(data.item.date).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <div style="flex: 1 1 auto"></div>
    </v-card>
    <DialogConfirmation ref="confirm"></DialogConfirmation>
  </div>
</template>
<script>
// import {mapGetters} from "vuex";
import {GET_RESERVATION_BY_ID, UPDATE_RESERVATIONS_DATA} from "@/core/services/store/reservations.module";
// import {FETCH_EVENTS_LIST} from "@/core/services/store/events.module";
// import {FETCH_DOSES_LIST} from "@/core/services/store/doses.module";
// import {GET_AVAILABILITY_BY_ID} from "@/core/services/store/availabilities.module";
import {mapGetters} from "vuex";
import DialogConfirmation from "@/view/commons/DialogConfirmation";

export default {
  data() {
    return {
      listComplete: {},
      isDisabled: false,
      dateReservation: {},
      filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      numberRule: [
        (v) => {
          // if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
          return this.$t(
              "PAGES.RESERVATIONS.DIALOG.MANAGE.ERROR_TOTAL_ALLOW_TRIAL"
          );
        },
      ],
      // [v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) || this.$t('PAGES.RESERVATIONS.DIALOG.MANAGE.ERROR_TOTAL_ALLOW_TRIAL')],
      // single_item_loanding: false,
      events_filters: {
        query: [],
        filters: {
          startDate: {_gt: new Date()},
        },
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 10,
      },
      doses_filters: {
        query: [],
        filters: {
          "event.id": {_eq: {}},
        },
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 10,
      },
    };
  },
  name: "EditReservation",
  props: ["single-item", "close-dialog", "single-item-loading"],
  computed: {
    eventsActiveList: function () {
      var listComplete = Object.assign([], this.eventsList.data);

      this.$set(listComplete, listComplete.length, this.singleItem.event);

      return listComplete;
    },
    dosesActiveList: function () {
      var listComplete = Object.assign([], this.singleItem.event.doses);
      this.$set(listComplete, listComplete.length, this.singleItem.dose);

      return listComplete;
    },
    datesActiveList: function () {
      var listComplete = Object.assign([], this.availabilityInUse);
      var actual = [];
      actual["date"] = this.singleItem.reservation_date_time;
      this.$set(listComplete, listComplete.length, actual);

      return listComplete;
    },
    ...mapGetters(["eventsList", "dosesList", "availabilityInUse", "reservationInUse"]),
  },
  methods: {
    owner() {
      return (
          this.singleItem.citizen.name + " " + this.singleItem.citizen.surname
      );
    },
    close() {
      this.$emit("close-dialog");
    },
    async editItem() {
      if (
          await this.$refs.confirm.open(
              this.$t("PAGES.RESERVATIONS.OVERVIEW.TITLE_EDIT_DIALOG"),
              this.$t("PAGES.RESERVATIONS.OVERVIEW.TEXT_EDIT_DIALOG")
          )
      ) {
        this.save();
        this.$router.push({name: "reservations_list"});
        // this.deleteRecord();
      }
    },
    save() {
      this.reservation = Object.assign({}, this.reservation, this.singleItem);
      this.$delete(this.reservation, "updatedAt");
      this.$delete(this.reservation, "createdAt");
      this.$delete(this.reservation, "citizen");
      this.$delete(this.reservation, "dose");
      this.$delete(this.reservation, "event");
      this.$delete(this.reservation, "code");
      this.reservation.dose = this.singleItem.dose.id;
      this.reservation.event = this.singleItem.event.id;

      this.singleItemLoading = true;
      this.$emit("dialogLoading", true);
      this.$store.dispatch(UPDATE_RESERVATIONS_DATA, this.reservation);
      setTimeout(() => this.$emit("dialogLoading", false), 2000);
      // setTimeout(() => (this.$emit('close-dialog')), 2500);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    // console.log(this.singleItem)
    // this.$store.dispatch(FETCH_EVENTS_LIST, this.events_filters);
    // // this.$store.dispatch(FETCH_DOSES_LIST, this.doses_filters);
    // if (this.singleItem !== undefined && this.singleItem.event !== undefined) {
    //   this.$store.dispatch(GET_AVAILABILITY_BY_ID, this.singleItem.event.id);
    // }
    this.$store.dispatch(GET_RESERVATION_BY_ID, this.$route.params.id);
  },
  components: {
    DialogConfirmation,
  },
  watch: {
    singleItemLoading(val) {
      if (!val) return;
      setTimeout(() => (this.singleItemLoading = false), 2000);
    },
  },
};
</script>
